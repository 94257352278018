import { CALIBRATION_URL, SCALEWAY_URL } from "./UrlConfig"

export const Calibration = () => {
  const calibration = (folderId, params) => {
    const formData = new FormData()
    formData.append("folder_id", folderId)
    if (params) {
      formData.append("board_size_width", params.squareNx)
      formData.append("board_size_height", params.squareNy)
      formData.append("square_size_meters", params.squareSize / 1000.0)
      formData.append("pxy_init", params.squarePXY)
    }
    console.log(
      `Launch Calibration on server on folder id ${folderId} with Form body:`
    )
    console.log(...formData)
    // send a multipart/form-data
    return fetch(`${CALIBRATION_URL}`, {
      method: "POST",
      body: formData,
    })
      .then((res) => res.json())
      .catch((err) => console.log(`ERROR RUNNING CALIBRATION : ${err.message}`))
  }

  return {
    calibration,
  }
}

export const Scaleway = () => {
  const getScalewayState = () => {
    return fetch(`${SCALEWAY_URL}`, {
      method: "GET",
    })
      .then((res) => res.json())
      .catch((err) => console.log(`ERROR GETTING SCALEWAY STATE : ${err.message}`))
  }

  const sendScalewayAction = (action) => {
    // action can be "start", "stop" or "switch"
    return fetch(`${SCALEWAY_URL}`, {
      method: "POST",
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ action: action })
    })
    .then((res) => res.json())
    .catch((err) => console.log(`ERROR SENDING SCALEWAY ACTION : ${err.message}`))
  }

  return {
    getScalewayState,
    sendScalewayAction,
  }
}
