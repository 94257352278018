import React, { useEffect, useState } from "react"
import { TiPencil } from "react-icons/ti"
import { GrCatalog } from "react-icons/gr"
import {
  FiPlay,
  FiUploadCloud,
  FiFolderPlus,
  FiTrash2,
  FiLink,
  FiCopy,
  FiDownload,
  FiVideo,
  FiBox,
  FiCornerUpLeft,
  FiHome,
} from "react-icons/fi"
import { FaMagnet, FaList, FaPowerOff } from "react-icons/fa"
import { BsCrop, BsLayers } from "react-icons/bs"
import { AiOutlineSelect, AiOutlineDotChart } from "react-icons/ai"
import {
  MdColorLens,
  MdGroupWork,
  MdSettingsBrightness,
  MdPhotoSizeSelectLarge,
} from "react-icons/md"
import { HiClipboardCopy } from "react-icons/hi"
import { BiGridHorizontal } from "react-icons/bi"
import { RiSave3Fill } from "react-icons/ri"
import { ImPaste } from "react-icons/im"
import { PiTargetLight } from "react-icons/pi"

import ReactTooltip from "react-tooltip"

import { ViewMode, SelectionMode, StorageKeys } from "../common/AppMode"
import { SvgHmkLoader } from "../common/HMKLoader"

import "./Menu.css"
import { isPicture, isPicFromStep } from "../common/CheckFileType"
import { DropDown } from "../board/Card"
import { hasExtraData, clamp, getShortenLabel } from "../common/utils"
import { STUDIO_URL } from "../io/UrlConfig"
import { useSessionStorageListener } from "../common/hooks/useSessionStorageListener"

const MenuUpload = (props) => (
  <div className="nav-link navbar-btn-action">
    <label htmlFor="multi">
      <FiUploadCloud data-tip="Upload Files" data-for="rtt_upload" />
      <ReactTooltip id="rtt_upload" effect="solid" place="bottom" />
    </label>
    <input
      className="menuitemupload"
      type="file"
      id="multi"
      onChange={props.onChange}
      style={{ display: "none" }}
      multiple
    />
  </div>
)

const handleHome = () => {
  window.open(STUDIO_URL, "studio.holomake.fr")
}

const MenuStudio = () => (
  <div
    className="nav-link navbar-btn-action nav-link-path"
    onClick={handleHome}
  >
    <GrCatalog data-tip="Projects" data-for="rtt_studio_projects" />
    <ReactTooltip id="rtt_studio_projects" effect="solid" place="bottom" />
  </div>
)

const MenuBack = ({ handleBack }) => (
  <div className="nav-link navbar-btn-action" onClick={handleBack}>
    <FiCornerUpLeft data-tip="Back" data-for="rtt_navbar_brand_back" />
    <ReactTooltip id="rtt_navbar_brand_back" effect="solid" place="bottom" />
  </div>
)

const MenuRoot = ({ handleRoot }) => (
  <div className="nav-link navbar-btn-action" onClick={handleRoot}>
    <FiHome data-tip="Home" data-for="rtt_navbar_brand_root" />
    <ReactTooltip id="rtt_navbar_brand_root" effect="solid" place="bottom" />
  </div>
)

const MenuBackComposer = ({ handleBack }) => (
  <div className="nav-link navbar-btn-action" onClick={handleBack}>
    <FiCornerUpLeft
      id="menu_composer_back"
      data-tip="Back (without saving)"
      data-for="rtt_navbar_brand_back_composer"
    />
    <ReactTooltip
      id="rtt_navbar_brand_back_composer"
      effect="solid"
      place="bottom"
    />
  </div>
)
const MenuSaveComposer = ({ handleBack }) => (
  <div className="nav-link navbar-btn-action" onClick={handleBack}>
    <RiSave3Fill
      id="menu_composer_save"
      data-tip="Save & Back"
      data-for="rtt_navbar_brand_save_composer"
    />
    <ReactTooltip
      id="rtt_navbar_brand_save_composer"
      effect="solid"
      place="bottom"
    />
  </div>
)

/*
const MenuCalibration = ({ handleMenu }) => (
  <div
    className="nav-link navbar-btn-action"
    onClick={(e) => handleMenu(e, { type: "calibration" })}
  >
    <FiCamera data-tip="Calibration Launcher" data-for="rtt_calibration" />
    <ReactTooltip id="rtt_calibration" effect="solid" place="bottom" />
  </div>
)

const MenuCalibrationPattern = ({ handleMenu }) => (
  <div
    className="nav-link navbar-btn-action"
    onClick={(e) => handleMenu(e, { type: "calibration_pattern" })}
  >
    <FaChessBoard
      data-tip="Display Calibration Pattern"
      data-for="rtt_calibration_pattern"
    />
    <ReactTooltip id="rtt_calibration_pattern" effect="solid" place="bottom" />
  </div>
)
*/

const MenuScwSwitch = ({ handleMenu, scwState }) => (
  <div
    className="nav-link navbar-btn-action"
    onClick={(e) => handleMenu(e, { type: "scwSwitch" })}
  >
    <FaPowerOff data-tip="Power On/Off GPU" data-for="rtt_scw_switch" color={scwState==="running" ? "green" : "red"} />
    <ReactTooltip id="rtt_scw_switch" effect="solid" place="bottom" />
  </div>
)

const MenuAddFolder = ({ handleMenu }) => (
  <div
    className="nav-link navbar-btn-action"
    onClick={(e) => handleMenu(e, { type: "addFolder" })}
  >
    <FiFolderPlus data-tip="Add Folder" data-for="rtt_add_folder" />
    <ReactTooltip id="rtt_add_folder" effect="solid" place="bottom" />
  </div>
)

const MenuTrack = ({ handleMenu, item }) => (
  <div
    className="nav-link navbar-btn-action"
    onClick={(e) => handleMenu(e, { type: "track", payload: item })}
  >
    <PiTargetLight data-tip="Track Label (T)" data-for="rtt_card_action_track" />
    <ReactTooltip
      id="rtt_card_action_track"
      effect="solid"
      place="bottom"
    />
  </div>
)

const MenuClear = ({ handleMenu }) => (
  <div
    className="nav-link navbar-btn-action"
    onClick={(e) => handleMenu(e, { type: "clearFolder" })}
  >
    <FiTrash2 data-tip="Clear" data-for="rtt_clear_folder" />
    <ReactTooltip id="rtt_clear_folder" effect="solid" place="bottom" />
  </div>
)

const MenuCopyLink = ({ handleMenu, item }) => (
  <div
    className="nav-link navbar-btn-action"
    onClick={(e) => handleMenu(e, { type: "copyLink", payload: item })}
  >
    <FiLink data-tip="Copy Link" data-for="rtt_card_action_copy_link" />
    <ReactTooltip
      id="rtt_card_action_copy_link"
      effect="solid"
      place="bottom"
    />
  </div>
)

const MenuCopyFile = ({ handleMenu, item }) => (
  <div
    className="nav-link navbar-btn-action"
    onClick={(e) => handleMenu(e, { type: "copyFile", payload: item })}
  >
    <FiCopy data-tip="Duplicate" data-for="rtt_card_action_copy_file" />
    <ReactTooltip
      id="rtt_card_action_copy_file"
      effect="solid"
      place="bottom"
    />
  </div>
)

const MenuCopyGeom = ({ handleMenu, item }) => (
  <div
    className="nav-link navbar-btn-action"
    onClick={(e) => handleMenu(e, { type: "copyGeom", payload: item })}
  >
    <BsLayers data-tip="Copy Geometries" data-for="rtt_card_action_copy_geom" />
    <ReactTooltip
      id="rtt_card_action_copy_geom"
      effect="solid"
      place="bottom"
    />
  </div>
)

const MenuPasteGeom = ({ handleMenu, item }) => (
  <div
    className="nav-link navbar-btn-action"
    onClick={(e) => handleMenu(e, { type: "pasteGeom", payload: item })}
  >
    <ImPaste
      data-tip="Paste Geometries"
      data-for="rtt_card_action_paste_geom_composer"
    />
    <ReactTooltip
      id="rtt_card_action_paste_geom_composer"
      effect="solid"
      place="bottom"
    />
  </div>
)

const MenuDownloadFile = ({ handleMenu, item }) => (
  <div
    className="nav-link navbar-btn-action"
    onClick={(e) => handleMenu(e, { type: "downloadFile", payload: item })}
  >
    <FiDownload data-tip="Download" data-for="rtt_card_action_dl" />
    <ReactTooltip id="rtt_card_action_dl" effect="solid" place="bottom" />
  </div>
)

const MenuProjectOn = ({ handleMenu, item, devices }) => {
  const [defaultDeviceName, setDefaultDeviceName] = useState("")
  useEffect(() => {
    if (devices && devices.default) {
      const dev = devices.list.find((dev) => dev.key === devices.default)
      setDefaultDeviceName(dev.name)
    }
  }, [devices])
  return (
    <div
      className="nav-link navbar-btn-action"
      onClick={(e) =>
        handleMenu(e, {
          type: "launchViewer",
          payload: item,
          deviceId: devices.default,
        })
      }
    >
      <FiPlay
        data-tip={`Project on ${defaultDeviceName}`}
        data-for="rtt_card_action_project_on"
      />
      <ReactTooltip
        id="rtt_card_action_project_on"
        effect="solid"
        place="bottom"
      />
    </div>
  )
}

const MenuProjectOnDevice = ({ handleMenu, item, devices }) => {
  const [showDropDown, setShowDropDown] = useState(false)
  return (
    <>
      <div
        className="nav-link navbar-btn-action"
        onClick={() => setShowDropDown(true)}
      >
        <svg
          width="1em"
          height="1em"
          data-tip="Project On Device"
          data-for="rtt_card_action_project_on_device"
        >
          <use href="play_more.svg#svg4"></use>
        </svg>
        <ReactTooltip
          id="rtt_card_action_project_on_device"
          effect="solid"
          place="bottom"
        />
        <div style={{ position: "absolute" }}>
          {showDropDown && (
            <DropDown
              devices={devices.list}
              handleMenu={handleMenu}
              item={item}
              hide={() => setShowDropDown(false)}
              marginTop={5}
            />
          )}
        </div>
      </div>
    </>
  )
}

const MenuHDRender = ({ handleMenu, item }) => (
  <div
    className="nav-link navbar-btn-action"
    onClick={(e) => handleMenu(e, { type: "hdRender", payload: item })}
  >
    <FiVideo data-tip="Render" data-for="rtt_card_action_hd" />
    <ReactTooltip id="rtt_card_action_hd" effect="solid" place="bottom" />
  </div>
)

const MenuGetGLTF = ({ handleMenu, item }) => (
  <div
    className="nav-link navbar-btn-action"
    onClick={(e) => handleMenu(e, { type: "getObj", payload: item })}
  >
    <FiBox data-tip="Get 3D File (GLTF)" data-for="rtt_card_action_gltf" />
    <ReactTooltip id="rtt_card_action_gltf" effect="solid" place="bottom" />
  </div>
)

const MenuEdition = ({ handleMenu, item }) => (
  <div
    className="nav-link navbar-btn-action"
    onClick={(e) => handleMenu(e, { type: "edition", payload: item })}
  >
    <TiPencil data-tip="Composer" data-for="rtt_card_action_edit" />
    <ReactTooltip id="rtt_card_action_edit" effect="solid" place="bottom" />
  </div>
)

const MenuColor = ({ handleMenu, item }) => (
  <div
    id="menu_color"
    className="nav-link navbar-btn-action"
    onClick={(e) => handleMenu(e, { type: "pv_color", payload: item })}
  >
    <MdColorLens data-tip="Color" data-for="rtt_card_action_color" />
    <ReactTooltip id="rtt_card_action_color" effect="solid" place="bottom" />
  </div>
)

const MenuResize = ({ handleMenu, item, canUseResizingTool }) => (
  <div
    id="menu_resize"
    className="nav-link navbar-btn-action"
    style={{ cursor: canUseResizingTool ? "pointer" : "not-allowed" }}
    onClick={(e) => {
      if (!canUseResizingTool) {
        return
      }
      handleMenu(e, { type: "pv_resize", payload: item })
    }}
  >
    <MdPhotoSizeSelectLarge
      data-tip="Resize"
      data-for="rtt_card_action_resize"
      color={canUseResizingTool ? "" : "#BFBFB4"}
    />
    <ReactTooltip
      id="rtt_card_action_resize"
      effect="solid"
      place="bottom"
      globalEventOff="click"
    />
  </div>
)

const MenuImageEdit = ({ handleMenu, item }) => (
  <div
    id="menu_imgedit"
    className="nav-link navbar-btn-action"
    onClick={(e) => handleMenu(e, { type: "pv_imgedit", payload: item })}
  >
    <MdSettingsBrightness
      data-tip="Brightness & Contrast"
      data-for="rtt_card_action_imgedit"
    />
    <ReactTooltip
      id="rtt_card_action_imgedit"
      effect="solid"
      place="bottom"
      globalEventOff="click"
    />
  </div>
)

const MenuCopyToClipBoard = ({ handleMenu, item }) => (
  <div
    className="nav-link navbar-btn-action"
    onClick={(e) => handleMenu(e, { type: "copy2clipboard", payload: item })}
  >
    <HiClipboardCopy
      data-tip="Copy to Clipboard (viewpoint,selection)"
      data-for="rtt_card_action_copy2clipboard"
    />
    <ReactTooltip
      id="rtt_card_action_copy2clipboard"
      effect="solid"
      place="bottom"
    />
  </div>
)

const MenuDelete = ({ handleMenu, item }) => (
  <div
    className="nav-link navbar-btn-action"
    onClick={(e) => handleMenu(e, { type: "delete", payload: item })}
  >
    <FiTrash2 data-tip="Delete" data-for="rtt_card_action_delete" />
    <ReactTooltip id="rtt_card_action_delete" effect="solid" place="bottom" />
  </div>
)

const MenuList = ({ handleMenu }) => (
  <div>
    <div
      className="nav-link navbar-btn-action selected"
      onClick={(e) => handleMenu(e, { type: "list" })}
    >
      <FaList data-tip="Objects ('e')" data-for="rtt_card_action_list" />
    </div>
    <ReactTooltip id="rtt_card_action_list" effect="solid" place="bottom" />
  </div>
)

const MenuDebug = ({ handleMenu }) => (
  <div>
    <div
      className="nav-link navbar-btn-action"
      onClick={(e) => handleMenu(e, { type: "debug" })}
    >
      <AiOutlineDotChart
        data-tip="Display 3D Vertices"
        data-for="rtt_card_action_debug"
      />
    </div>
    <ReactTooltip id="rtt_card_action_debug" effect="solid" place="bottom" />
  </div>
)

const MenuMagnet = ({ handleMenu }) => (
  <div>
    <div
      className="nav-link navbar-btn-action"
      onClick={(e) => handleMenu(e, { type: "magnet" })}
    >
      <FaMagnet data-tip="Magnet" data-for="rtt_card_action_magnet" />
    </div>
    <ReactTooltip id="rtt_card_action_magnet" effect="solid" place="bottom" />
  </div>
)

const MenuSelection = ({ handleMenu }) => (
  <div>
    <div
      className="nav-link navbar-btn-action"
      onClick={(e) => handleMenu(e, { type: "selection" })}
    >
      <AiOutlineSelect data-tip="Select" data-for="rtt_card_action_selection" />
    </div>
    <ReactTooltip
      id="rtt_card_action_selection"
      effect="solid"
      place="bottom"
    />
  </div>
)

const MenuSelectGroup = ({ handleMenu, item, selected }) => (
  <div>
    <div
      className={`nav-link navbar-btn-action ${selected ? "selected" : ""}`}
      onClick={(e) => handleMenu(e, { type: "select_group", payload: item })}
    >
      <MdGroupWork
        data-tip="Group Selection"
        data-for="rtt_card_action_group_select"
      />
    </div>
    <ReactTooltip
      id="rtt_card_action_group_select"
      effect="solid"
      place="bottom"
    />
  </div>
)

const MenuPointInteractor = ({ handleMenu, item, selected }) => (
  <div>
    <div
      className={`nav-link navbar-btn-action ${selected ? "selected" : ""}`}
      onClick={(e) =>
        handleMenu(e, { type: "point_interactor", payload: item })
      }
    >
      <BiGridHorizontal
        data-tip="Keypoints"
        data-for="rtt_card_action_point_interactor"
      />
    </div>
    <ReactTooltip
      id="rtt_card_action_point_interactor"
      effect="solid"
      place="bottom"
    />
  </div>
)

const MenuCrop = ({ handleMenu, item, selectBox }) => (
  <div>
    <div
      className="nav-link navbar-btn-action"
      style={{ cursor: selectBox ? "pointer" : "not-allowed" }}
      onClick={(e) => {
        if (!selectBox) {
          return
        }
        handleMenu(e, { type: "crop", payload: item })
      }}
    >
      <BsCrop
        data-tip="Crop"
        data-for="rtt_card_action_crop"
        color={selectBox ? "" : "#BFBFB4"}
      />
    </div>
    <ReactTooltip id="rtt_card_action_crop" effect="solid" place="bottom" />
  </div>
)

/*
const MenuToggle3D = ({ handleMenu }) => (
  <div>
    <div
      className="nav-link navbar-btn-action menu_composer_3d_icon"
      //onClick={(e) => toggle3D()}
      onClick={(e) => handleMenu(e, { type: "3d" })}
    >
      <FaCube
        id="menu_composer_3d_cube"
        data-tip="Toggle 2D/3D mode"
        data-for="rtt_card_action_menu_3d"
      />
      <MdLooksTwo
        id="menu_composer_3d_square"
        data-tip="Toggle 2D/3D mode"
        data-for="rtt_card_action_menu_3d"
      />
    </div>
    <ReactTooltip id="rtt_card_action_menu_3d" effect="solid" place="bottom" />
  </div>
)
*/

const MenuConverter = ({ handleUpload, handleMenu, scwState }) => (
  <div className="navbar-menu-custom">
    <li className="nav-item">
      <MenuAddFolder handleMenu={handleMenu} />
    </li>
    <li className="nav-item">
      <MenuUpload onChange={handleUpload} />
    </li>
    <li className="nav-item">
      <MenuScwSwitch handleMenu={handleMenu} scwState={scwState} />
    </li>
    {/**
    <li className="nav-item">
      <MenuClear handleMenu={handleMenu} />
    </li>
    */}
    {/**
    <li className="nav-item">
      <MenuCalibration handleMenu={handleMenu} />
    </li>
    <li className="nav-item">
      <MenuCalibrationPattern handleMenu={handleMenu} />
    </li>
    */}
  </div>
)

const MenuComposer = ({ handleMenu, item, geomInClipboard, geomInPic }) => (
  <div id="menu_composer" className="navbar-menu-custom">
    {/**
    <li id="menu_composer_3d">
      <MenuToggle3D handleMenu={handleMenu} />
    </li>
    */}
    {/** This item always */}
    {/**
     *
    <li id="menu_composer_copylink" className="nav-item">
      <MenuCopyLink handleMenu={handleMenu} item={item} />
    </li>
    */}
    {/** This item only if 2D Mode */}
    <li id="menu_composer_track" className="nav-item">
      <MenuTrack handleMenu={handleMenu} item={item} />
    </li>
    {/** This item only if 2D Mode */}
    <li id="menu_composer_copyfile" className="nav-item">
      <MenuCopyFile handleMenu={handleMenu} item={item} />
    </li>
    {/** This item only if 2D Mode */}
    {geomInPic && (
      <li id="menu_composer_copygeom" className="nav-item">
        <MenuCopyGeom handleMenu={handleMenu} item={item} />
      </li>
    )}
    {/** This item only if there are geometries to paste */}
    {geomInClipboard && (
      <li id="menu_composer_pastegeom" className="nav-item">
        <MenuPasteGeom handleMenu={handleMenu} item={item} />
      </li>
    )}
    {/** This item always */}
    <li id="menu_composer_list" className="nav-item">
      <MenuList handleMenu={handleMenu} />
    </li>
    {/** This item only if 3D Mode */}
    <li id="menu_composer_debug" className="nav-item">
      <MenuDebug handleMenu={handleMenu} />
    </li>
    {/** This item only if 3D Mode */}
    <li id="menu_composer_magnet" className="nav-item">
      <MenuMagnet handleMenu={handleMenu} />
    </li>
    {/** This item only in SVG mode */}
    <li id="menu_composer_crop" className="nav-item">
      <MenuCrop handleMenu={handleMenu} />
    </li>
    {/** This item always */}
    <li id="menu_composer_selection" className="nav-item">
      <MenuSelection handleMenu={handleMenu} />
    </li>
  </div>
)

const MenuPictureViewer = ({
  handleMenu,
  item,
  selectMode,
  devices,
  geomInClipboard,
  selectBox,
  geomInPic,
}) => {
  const picFromStep = isPicFromStep(item)
  const canUseResizingTool = item.metadata.size
    ? Boolean(item.metadata.size[0] > 1920 || item.metadata.size[1] > 1080)
    : false
  return (
    <div className="navbar-menu-custom">
      {devices?.default ? (
        <li className="nav-item">
          <MenuProjectOn
            handleMenu={handleMenu}
            item={item}
            devices={devices}
          />
        </li>
      ) : (
        <></>
      )}
      {devices?.list?.length ? (
        <li className="nav-item">
          <MenuProjectOnDevice
            handleMenu={handleMenu}
            item={item}
            devices={devices}
          />
        </li>
      ) : (
        <></>
      )}
      <li className="nav-item">
        <MenuCopyFile handleMenu={handleMenu} item={item} />
      </li>
      <li className="nav-item">
        <MenuCopyLink handleMenu={handleMenu} item={item} />
      </li>
      {picFromStep && (
        <li className="nav-item">
          <MenuCopyToClipBoard handleMenu={handleMenu} item={item} />
        </li>
      )}
      <li className="nav-item">
        <MenuDownloadFile handleMenu={handleMenu} item={item} />
      </li>
      {picFromStep && (
        <li className="nav-item">
          <MenuHDRender handleMenu={handleMenu} item={item} />
        </li>
      )}
      {picFromStep && (
        <li className="nav-item">
          <MenuGetGLTF handleMenu={handleMenu} item={item} />
        </li>
      )}
      {picFromStep && (
        <li className="nav-item">
          <MenuSelectGroup
            handleMenu={handleMenu}
            item={item}
            selected={selectMode === SelectionMode.PARENT_GROUP}
          />
        </li>
      )}
      {/** If we don't want crop function on 3d based picture, enable the if.
       * In case we do crop in 3d based folder, it will add a non 3d picture in the list .... that will break the composition on 3d pictures.
       */}
      {!picFromStep ? (
        <li className="nav-item">
          <MenuCrop handleMenu={handleMenu} item={item} selectBox={selectBox} />
        </li>
      ) : (
        <li className="nav-item">
          <MenuCrop handleMenu={handleMenu} item={item} selectBox={selectBox} />
        </li>
      )}
      <li className="nav-item">
        {/** using Thumbnail API */}
        <MenuResize
          handleMenu={handleMenu}
          item={item}
          canUseResizingTool={canUseResizingTool}
        />
      </li>
      <li className="nav-item">
        <MenuImageEdit handleMenu={handleMenu} item={item} />
      </li>
      <li className="nav-item">
        <MenuEdition handleMenu={handleMenu} item={item} />
      </li>
      {geomInPic && (
        <li className="nav-item">
          <MenuCopyGeom handleMenu={handleMenu} item={item} />
        </li>
      )}
      {geomInClipboard && (
        <li className="nav-item">
          <MenuPasteGeom handleMenu={handleMenu} item={item} />
        </li>
      )}
      <li className="nav-item">
        <MenuPointInteractor
          handleMenu={handleMenu}
          item={item}
          selected={selectMode === SelectionMode.POINT_SELECTOR}
        />
      </li>
      {picFromStep && (
        <li className="nav-item">
          <MenuColor handleMenu={handleMenu} item={item} />
        </li>
      )}
      <li className="nav-item">
        <MenuDelete handleMenu={handleMenu} item={item} />
      </li>
    </div>
  )
}

const PathElement = ({ label, last, onClick }) => (
  <>
    <div onClick={() => !last && onClick()}>
      <span className={`${!last && "root-navigation"}`}>{label}</span>
    </div>
    <span>{`${!last ? "/" : ""}`}</span>
  </>
)

const preventBubbling = (e) => e.stopPropagation()

const Menu = ({
  title,
  titleInfo,
  mode,
  selectMode,
  handleMenu,
  handleUpload,
  handleBack,
  showBack,
  item,
  devices,
  currentPath,
  onNavigate,
  selectBox,
  scwState,
}) => {
  const [is3Dmode, setis3Dmode] = useState(false)
  const geomInClipboard = useSessionStorageListener(StorageKeys().CLIPBOARD_GEOM_ID_KEY)
  const titlePathElts = titleInfo.includes("/") ? titleInfo.split("/") : []
  const isGeomInPic = item && isPicture(item.src) && item.metadata && item.metadata.geom_compo_id

  return (
    <div>
      <nav
        className="navbar fixed-top navbar-expand-lg navbar-dark"
        onMouseDown={preventBubbling}
        onMouseMove={preventBubbling}
        onMouseUp={preventBubbling}
      >
        <div className="navbar-brand">
          <div
            className="d-inline-block align-top logo"
            onClick={handleHome}
            data-tip="Studio"
            data-for="rtt_navbar_brand_home"
          >
            <SvgHmkLoader />
          </div>
          <ReactTooltip
            id="rtt_navbar_brand_home"
            effect="solid"
            place="right"
          />
          {/*
          {showBack ? (
            <button onClick={handleBack}>
              <FiCornerUpLeft
                data-tip="Back"
                data-for="rtt_navbar_brand_back"
              />
              <ReactTooltip
                id="rtt_navbar_brand_back"
                effect="solid"
                place="bottom"
              />
            </button>
          ) : (
            ""
          )}
          */}
          <span className="navbar-brand-title">{title}</span>
        </div>
        <div>
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon" />
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav ml-auto">
              <li className="nav-item">
                <MenuStudio />
              </li>
              {mode === ViewMode.COMPOSER ? (
                <>
                  <li className="nav-item">
                    <MenuBackComposer handleBack={() => handleBack(false)} />
                  </li>
                  <li className="nav-item">
                    <MenuSaveComposer handleBack={() => handleBack(true)} />
                  </li>
                </>
              ) : showBack ? (
                <>
                  {mode !== ViewMode.VIEW2D && (
                    <li className="nav-item">
                      <MenuRoot
                        handleRoot={() => onNavigate(currentPath[0].id, 0)}
                      />
                    </li>
                  )}
                  <li className="nav-item">
                    <MenuBack handleBack={handleBack} />
                  </li>
                </>
              ) : (
                <></>
              )}
              <li className="nav-text-path">
                {titlePathElts.length ? (
                  titlePathElts.map((i, index) => {
                    const notLastElement = Boolean(
                      index < titlePathElts.length - 1
                    )
                    return (
                      <PathElement
                        key={i}
                        label={i.trim()}
                        last={!notLastElement}
                        onClick={() => onNavigate(currentPath[index].id, index)}
                      />
                    )
                  })
                ) : (
                  <span>{getShortenLabel(titleInfo, 80)}</span>
                )}
              </li>
              {mode === ViewMode.CONVERTER ? (
                <>
                  {!hasExtraData(
                    currentPath[clamp(currentPath.length - 1, 0, Infinity)]
                  ) && (
                    <MenuConverter
                      handleUpload={handleUpload}
                      handleMenu={handleMenu}
                      scwState={scwState}
                    />
                  )}
                </>
              ) : mode === ViewMode.VIEW2D ? (
                <MenuPictureViewer
                  handleMenu={handleMenu}
                  item={item}
                  selectMode={selectMode}
                  devices={devices}
                  geomInClipboard={Boolean(geomInClipboard)}
                  selectBox={Boolean(selectBox)}
                  geomInPic={isGeomInPic}
                />
              ) : (
                mode === ViewMode.COMPOSER && (
                  <MenuComposer
                    handleMenu={handleMenu}
                    item={item}
                    is3Dmode={is3Dmode}
                    toggle3D={() => setis3Dmode(!is3Dmode)}
                    geomInClipboard={Boolean(geomInClipboard)}
                    geomInPic={isGeomInPic}
                  />
                )
              )}
            </ul>
          </div>
        </div>
      </nav>
    </div>
  )
}

export default Menu
