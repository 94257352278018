import { isJSON } from "../common/CheckFileType"
import {
  UPLOAD_URL,
  DIRECTORY_URL,
  CONVERT_URL,
  COMPOSE_URL,
  ROTATE_URL,
  THUMBNAIL_URL,
} from "./UrlConfig"

export const HMFileIO = (token) => {
  const DEBUG = false
  const USER = "holomake"
  const TOKEN = token

  /**
   * Rotate a picture file server side
   * @param {*} fileId
   */
  const rotate = (fileId, rotation, apply) => {
    const formData = new FormData()
    formData.append("file", fileId)
    formData.append("rotation", rotation)
    formData.append("apply", apply)
    // send a multipart/form-data
    return fetch(`${ROTATE_URL}`, {
      method: "POST",
      body: formData,
    })
      .then((res) => res.json())
      .catch((err) => console.log(`ERROR ROTATING FILE : ${err.message}`))
  }

  /**
   * Call the Composer web service in picture ID
   * @param {string} pictureId
   */
  const compose = (pictureId) => {
    const formData = new FormData()
    formData.append("picture_id", pictureId)
    // send a multipart/form-data
    return fetch(`${COMPOSE_URL}`, {
      method: "POST",
      body: formData,
    })
      .then((res) => res.json())
      .catch((err) => console.log(`ERROR CREATING COMPOSER : ${err.message}`))
  }

  /**
   * Convert file through action if it's a STEP file.
   * action can ben step2tree, step2obj or render
   * @param {*} fileId
   */
  const convert = (action, fileId, params) => {
    const formData = new FormData()
    formData.append("action", action)
    formData.append("file_id", fileId)
    if (params) {
      formData.append("params", JSON.stringify(params))
    }
    // send a multipart/form-data
    return fetch(`${CONVERT_URL}`, {
      method: "POST",
      body: formData,
    })
      .then((res) => {
        if (res.ok) return res.json()
        else throw Error(res.statusText)
      })
      .catch((err) => {
        console.log(`ERROR CREATING CONVERTER : ${err.message}`)
        throw Error(err.message)
      })
  }

  /**
   * Track web service.
   * @param {*} fileId
   */
  const track = (fileId, params) => {
    const payload = { "file_id": fileId, "params": params }
    // Send JSON content type data as payload
    return fetch(`${CONVERT_URL}track/`, {
      method: "POST",
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(payload),
    })
    .then((res) => {
      if (res.ok) return res.json()
      else throw Error(res.statusText)
    })
    .catch((err) => {
      console.log(`ERROR CREATING TRACK : ${err.message}`)
      throw Error(err.message)
    })
  }

  const loadDir = (key) => {
    const url = DIRECTORY_URL + key
    return fetch(url)
      .then((resp) => resp.json())
      .catch((err) => console.log(`ERROR LOADING DIRECTORY : ${err.message}`))
  }

  const loadFile = (key) => {
    const url = UPLOAD_URL + key
    return fetch(url)
      .then((resp) => resp.json())
      .catch((err) => console.log(`ERROR LOADING FILE : ${err.message}`))
  }

  const getDevicesFile = (folderId) => {
    const url1 = UPLOAD_URL + "from_name/"
    const formData = new FormData()
    formData.append("folder_id", folderId)
    formData.append("name", "devices.json")
    const headers = new Headers()
    headers.append("Authorization", "Token " + TOKEN)
    return fetch(`${url1}`, {
      method: "POST",
      headers: headers,
      body: formData,
    })
      .then((resp) => resp.json())
      .then((jsFile) => {
        const url2 = UPLOAD_URL + jsFile[0].id + "/get_content/"
        const headers = new Headers()
        headers.append("Authorization", "Token " + TOKEN)
        return fetch(url2, { method: "GET", headers: headers })
        //return fetch(jsFile[0].datafile, { method: "GET", headers: headers })
      })
      .then((resp) => resp.json())
      .catch((err) => console.log(`ERROR GETTING FILES : ${err.message}`))
  }

  const getFilesAndFolders = (folderId, tagList, hasTag) => {
    let url = DIRECTORY_URL + folderId + "/get_files_and_folders/"
    // build the tag list as query parameter string
    const param = hasTag ? "with_tags" : "wo_tags"
    const tagListStr = tagList.map((t) => `${param}=${t}`).join("&")
    url += `?${tagListStr}`
    const headers = new Headers()
    headers.append("Authorization", "Token " + TOKEN)
    return fetch(`${url}`, {
      method: "GET",
      headers: headers,
    })
      .then((resp) => resp.json())
      .catch((err) =>
        console.log(`ERROR GETTING FILES & FOLDERS : ${err.message}`)
      )
  }

  const createFolder = (name, parentId, description) => {
    const formData = new FormData()
    formData.append("owner", USER)
    formData.append("parent", parentId)
    formData.append("name", name)
    if (description) {
      formData.append("metadata", JSON.stringify({ description: description }))
    }
    // send a multipart/form-data
    return fetch(`${DIRECTORY_URL}`, {
      method: "POST",
      body: formData,
    })
      .then((res) => res.json())
      .then((dir) => {
        return {
          id: dir["id"],
          created: dir["created"],
          src: dir["name"],
          parent: dir["parent"],
          metadata: dir["metadata"],
        }
      })
      .catch((err) => console.log(`ERROR CREATING FOLDER : ${err.message}`))
  }

  const renameFolder = (newName, folderId, description) => {
    const formData = new FormData()
    formData.append("name", newName)
    if (description) {
      formData.append("metadata", JSON.stringify({ description: description }))
    }
    // send a multipart/form-data
    const url = DIRECTORY_URL + folderId + "/"
    return fetch(`${url}`, {
      method: "PATCH",
      body: formData,
    })
      .then((res) => res.json())
      .then((dir) => {
        return {
          id: dir["id"],
          created: dir["created"],
          src: dir["name"],
          parent: dir["parent"],
          metadata: dir["metadata"],
        }
      })
      .catch((err) => console.log(`ERROR UPDATING FOLDER : ${err.message}`))
  }

  const updateFileMetadata = (metadata, fileId) => {
    const formData = new FormData()
    if (metadata) {
      formData.append("metadata", JSON.stringify(metadata))
    }
    // send a multipart/form-data
    const url = UPLOAD_URL + fileId + "/"
    return fetch(`${url}`, {
      method: "PATCH",
      body: formData,
    })
      .then((res) => res.json())
      .then((file) => {
        return {
          id: file["id"],
          created: file["created"],
          src: file["name"],
          parent: file["parent"],
        }
      })
      .catch((err) => console.log(`ERROR UPDATING FILE : ${err.message}`))
  }

  const getNewName = async (key) => {
    const formData = new FormData()
    const url = UPLOAD_URL + key + "/get_new_name/"
    // send a multipart/form-data
    return fetch(url, {
      method: "POST",
      body: formData,
    })
      .then((res) => res.json())
      .then((res) => {
        return res
      })
      .catch((err) => console.log(`ERROR DURING GET NEW NAME : ${err.message}`))
  }

  const getFileDescriptor = (fileId) => {
    const url = UPLOAD_URL + fileId + "/get_descriptor/"
    // send a multipart/form-data
    return fetch(url, {
      method: "POST",
    })
      .then((res) => res.json())
      .then((res) => {
        return res
      })
      .catch((err) =>
        console.log(`ERROR DURING GET FILE DESCRIPTOR : ${err.message}`)
      )
  }

  const uploadFileXHR = (
    file,
    parentId,
    metadata = {},
    overwrite = false,
    thumbnail = null,
    overrideName = null
  ) => {
    return new Promise((resolve, reject) => {
      const req = new XMLHttpRequest()
      let filename = overrideName ?? file.name

      req.upload.addEventListener("progress", (event) => {
        if (event.lengthComputable) {
          const copy = {} // { ...this.state.uploadProgress }
          copy[filename] = {
            state: "pending",
            percentage: (event.loaded / event.total) * 100,
          }
          //this.setState({ uploadProgress: copy })
          if (DEBUG) console.log(`XHR: Upload Progress: `, copy)
        }
      })

      req.addEventListener("loadend", (event) => {
        const copy = {} //{ ...this.state.uploadProgress }
        copy[filename] = { state: "done", percentage: 100 }
        //this.setState({ uploadProgress: copy })
        const resp = JSON.parse(req.responseText)
        // TODO check the req.response object value !?
        const response = {
          ...resp,
          src: resp["datafile"],
        }
        resolve(response)
      })

      req.addEventListener("error", (event) => {
        const copy = {} //{ ...this.state.uploadProgress }
        copy[filename] = { state: "error", percentage: 0 }
        //this.setState({ uploadProgress: copy })
        console.log(`XHR: Load error: `, copy)
        reject(req.responseText)
      })

      const sendRequest = (overrideMetadata = null) => {
        if (DEBUG) console.log("XHR: Will try to upload file " + filename)
        const formData = new FormData()
        formData.append("datafile", file, filename)
        formData.append("owner", USER)
        formData.append("parent", parentId)
        formData.append("overwrite", overwrite)
        if (thumbnail) formData.append("thumbnail", JSON.stringify(thumbnail))
        formData.append("md", JSON.stringify(overrideMetadata ?? metadata))
        req.open("POST", UPLOAD_URL)
        req.send(formData)
      }

      if (isJSON(filename)) {
        //console.log("Uploaded a JSON file")
        const reader = new FileReader()
        reader.onloadend = (e) => {
          //console.log("on load reader e:", e)
          try {
            const obj = JSON.parse(e.target.result)
            //console.log(obj)
            sendRequest({
              ...metadata,
              command: obj.command || obj.command_label || null,
            })
          } catch (e) {
            // invalid JSON file, upload it as it
            console.error("Cannot upload invalid JSON file ", filename)
            reject(e)
          }
        }
        reader.readAsText(file)
      } else {
        sendRequest()
      }
    })
  }

  const deleteFolder = (key) => {
    const url = DIRECTORY_URL + key
    // send a multipart/form-data
    return fetch(url, {
      method: "DELETE",
    })
      .then((res) => {
        return res
      })
      .catch((err) => console.log(`ERROR DELETING FOLDER : ${err.message}`))
  }

  const duplicateFile = async (key, newNamePrefix, noOverwrite, params) => {
    const formData = new FormData()
    if (newNamePrefix) formData.append("new_name_prefix", newNamePrefix)
    if (noOverwrite) formData.append("no_overwrite", "True")
    if (params) formData.append("params", JSON.stringify(params))
    const url = UPLOAD_URL + key + "/duplicate/"
    // send a multipart/form-data
    try {
      const res = await fetch(url, {
        method: "POST",
        body: formData,
      })
      const res_1 = await res.json()
      return res_1
    } catch (err) {
      return console.log(`ERROR DURING FILE DUPLICATION : ${err.message}`)
    }
  }

  const deleteFile = (key) => {
    const url = UPLOAD_URL + key
    // send a multipart/form-data
    return fetch(url, {
      method: "DELETE",
    })
      .then((res) => {
        return res
      })
      .catch((err) => console.log(`ERROR DELETING FILE : ${err.message}`))
  }

  const createZipFile = (folderId) => {
    if (!folderId) throw Error("Missing folder id parameter.")
    const url = DIRECTORY_URL + folderId + "/build_zip_file/"
    return fetch(`${url}`, {
      method: "GET",
    })
      .then((res) => {
        if (res.ok) return res.json()
        else throw Error(res.statusText)
      })
      .catch((err) => {
        console.log(`ERROR BUILDING ZIP FILE : ${err.message}`)
        throw Error(err.message)
      })
  }

  const dlZipFile = (data) => {
    const formData = new FormData()
    formData.append("url", data.url)
    formData.append("name", data.name)
    const url = DIRECTORY_URL + "dl_zip_file/"
    return fetch(`${url}`, {
      method: "POST",
      body: formData,
    })
      .then((res) => {
        if (res.ok) return res.blob()
        else throw Error(res.statusText)
      })
      .then((res) => {
        var zipUrl = URL.createObjectURL(res)
        var tag = document.createElement("a")
        tag.href = zipUrl
        tag.download = data.name
        document.body.appendChild(tag)
        tag.click()
        document.body.removeChild(tag)
        return tag.download
      })
      .catch((err) => {
        console.log(`ERROR DOWNLOADING ZIP FILE : ${err.message}`)
        throw Error(err.message)
      })
  }

  /**
   * Create a thumbnail for the given file id on the server. The created thumbnail isn't registered in database, it's linked through metadata by its filename. The size parameter is two value JS array.
   * @param {string} fileId the server side file id to which create a thumbnail
   * @param {number[]} size the size of the thumbnail picture [width, height]
   * @param {boolean} isGeoCompo the thumbnail is for resizing geom compo bitmap image.
   * @returns the URL of the thumbnail picture. The prefered way to access it.
   */
  const thumbnail = (fileId, size, createDbEntry, isGeoCompo) => {
    const formData = new FormData()
    formData.append("file", fileId)
    formData.append("size", size)
    if (createDbEntry) formData.append("createDbEntry", createDbEntry)
    if (isGeoCompo) formData.append("isGeoCompo", isGeoCompo)
    // send a multipart/form-data
    return fetch(`${THUMBNAIL_URL}`, {
      method: "POST",
      body: formData,
    })
      .then((res) => res.json())
      .catch((err) => console.log(`ERROR THUMBNAILIZING FILE : ${err.message}`))
  }

  return {
    rotate,
    compose,
    convert,
    track,
    loadDir,
    loadFile,
    getFilesAndFolders,
    createFolder,
    renameFolder,
    uploadFileXHR,
    deleteFolder,
    deleteFile,
    duplicateFile,
    updateFileMetadata,
    getNewName,
    getFileDescriptor,
    getDevicesFile,
    createZipFile,
    dlZipFile,
    thumbnail,
  }
}
